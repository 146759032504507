<template>
  <div>
    <v-dialog
      :value="show"
      @input="$emit('close')"
      width="550"
    >
      <v-form
        class="justify-center filter-form pa-10"
        @submit.prevent="emitChange"
      >
        <v-row
          class="ma-0 mb-4"
        >
          <v-col class="pl-0">
            <h2>Фильтр</h2>
          </v-col>
          <v-col class="pr-0">
            <v-btn
              icon
              color="red accent-1"
              @click="$emit('close')"
              class="float-end"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <date-picker label="Дата от" v-model="dateFrom" />
        <date-picker label="Дата до" v-model="dateTo" />
        <v-text-field
          label="Стоимость"
          type="number"
          v-bind="$options.selectAttrs"
          v-model="price"
        />
        <v-text-field
          label="Комментарий"
          v-bind="$options.selectAttrs"
          v-model="comment"
        />
        <v-select
          label="Откуда узнал"
          :items="filtersData.how_finds"
          v-bind="$options.selectAttrs"
          v-model="howFindId"
        />
        <v-text-field
          label="Модель устройства"
          v-bind="$options.selectAttrs"
          v-model="deviceModel"
        />
        <v-select
          label="Вид услуги"
          :items="filtersData.services"
          v-bind="$options.selectAttrs"
          v-model="serviceId"
        />
        <v-select
          label="Торговая точка"
          :items="filtersData.sale_places"
          v-bind="$options.selectAttrs"
          v-model="salePlaceId"
        />
        <v-select
          label="Материал корпуса"
          :items="filtersData.back_materials"
          v-bind="$options.selectAttrs"
          v-model="backMaterialId"
        />
        <v-select
          label="Материал дисплея"
          :items="filtersData.screen_materials"
          v-bind="$options.selectAttrs"
          v-model="screenMaterialId"
        />
        <v-select
          label="Сотрудник"
          :items="filtersData.workers"
          v-bind="$options.selectAttrs"
          v-model="workerId"
        />
        <v-btn
          dark
          color="indigo"
          block
          type="submit"
        >
          Применить фильтр
        </v-btn>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>


import DatePicker from "@/components/DatePicker.vue";

export default {
  name: "FilterForm",
  components: { DatePicker },
  selectAttrs: {
    'item-text': "name",
    'item-value': "id",
    clearable: true,
    outlined: true,
    dense: true,
  },
  data() {
    return {
      menu: false,
      dateFrom: this.value.dateFrom ?? '',
      dateTo: this.value.dateTo ?? '',
      salePlaceId: this.value.salePlaceId ?? null,
      serviceId: this.value.serviceId ?? null,
      backMaterialId: this.value.backMaterialId ?? null,
      screenMaterialId: this.value.screenMaterialId ?? null,
      workerId: this.value.workerId ?? null,
      price: this.value.price ?? null,
      comment: this.value.comment ?? null,
      howFindId: this.value.howFindId ?? null,
      deviceModel: this.value.deviceModel ?? null,
    };
  },
  methods: {
    emitChange() {
      this.$emit('input', {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        salePlaceId: this.salePlaceId,
        serviceId: this.serviceId,
        backMaterialId: this.backMaterialId,
        screenMaterialId: this.screenMaterialId,
        workerId: this.workerId,
        price: this.price,
        comment: this.comment,
        howFindId: this.howFindId,
        deviceModel: this.deviceModel,
      });
    },
  },
  props: {
    show: { type: Boolean, required: true },
    filtersData: { type: Object, required: true },
    value: { type: Object, required: true }
  }
}
</script>

<style scoped>
  .filter-form {
    background: white;
  }
</style>
