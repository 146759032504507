<template>
  <div>
    <v-btn
      v-on:click="$router.go(-1)"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <v-container>
      <div class="block-title">
        Информация
      </div>
      <v-col
        cols="12"
        sm="6"
      >
        <v-text-field
          label="Имя"
          v-model="user.name"
          disabled
        ></v-text-field>
        <v-text-field
          label="Почта"
          v-model="user.email"
          disabled
        ></v-text-field>
      </v-col>
      <div class="block-title">
        Изменение пароля
      </div>
      <v-form
        ref="form"
        lazy-validation
        v-model="valid"
        @submit.prevent="changePassword"
      >
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            :rules="[$options.required, $options.passwordLength]"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            label="Пароль"
            placeholder="Введите пароль"
            v-model="user.password"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-text-field
            :rules="[
              $options.required,
              $options.passwordLength,
              $options.passwordConfirm(user.password, user.passwordConfirm)
            ]"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            label="Подтвердите пароль"
            placeholder="Введите пароль"
            v-model="user.passwordConfirm"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
        </v-col>
        <v-btn
          :disabled="!valid"
          type="submit"
        >
          Изменить пароль
        </v-btn>
        <v-btn
          type="button"
          @click="logout"
          style="margin-left: 10px;"
        >
          Выйти
        </v-btn>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import rulesMixin from "@/mixins/rulesMixin";
import {AUTH_LOGOUT} from "@/store/actions/auth";

/**
 * Конструктор пользователя
 *
 * @param name
 * @param email
 * @param password
 * @param passwordConfirm
 * @constructor
 */
function User(
  name = '',
  email = '',
  password = '',
  passwordConfirm = '',
) {
  this.name = name;
  this.email = email;
  this.password = password;
  this.passwordConfirm = passwordConfirm;
}

export default {
  created() {
    this.fetchUser();
  },
  data() {
    return {
      user: new User(),
      //form change password
      showPassword: false,
      valid: true,
    };
  },
  methods: {
    /**
     * Получить пользователя
     */
    fetchUser() {
      this.axios.get(`/api/user-profile`)
      .then((response) => {
        const user = response.data.user;
        this.user = new User(user.name, user.email);
      }).catch((err) => {
        console.error(err);
      });
    },
    changePassword() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.axios.put('/api/user-profile/change-password',
      {
        password: this.user.password,
      }).then((response) => {
        this.$router.go(0);
      }).catch((err) => {
        console.error(err);
      })
    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$router.push({name: 'Auth'})
      });
    }
  },
  mixins: [ rulesMixin ],
  name: "UserProfile",
}
</script>

<style scoped>
.block-title {
  margin-top: 20px;
}
</style>
