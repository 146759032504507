<template>
  <div
    class="panel"
  >
    <v-app-bar>
      <v-btn @click="$router.push({name: 'OrderList'})">
        Вернуться на список
      </v-btn>
    </v-app-bar>
    <v-layout
      class="ma-0"
      justify-center
      row
      fill-height
    >
      <v-btn
        class="item"
        v-on:click="$router.push({ name: 'AdminUsers' })"
      >
        Пользователи
      </v-btn>
      <v-btn
        class="item"
        v-on:click="$router.push({ name: 'AdminTables' })"
      >
        Словари
      </v-btn>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "AdminPanel"
}
</script>

<style scoped>
.item {
  min-height: 100px;
  min-width: 200px;
  margin: 10px;
}
.panel {
  width: 100%;
  height: 100%;
}
.card-text {
  text-decoration: none;
}
</style>
