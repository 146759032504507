<template>
  <div>
    <v-row
      justify="center"
      align="center"
      class="ma-0"
    >
      <h1>
        Protect-Mobile
      </h1>
    </v-row>
    <v-row
      justify="center"
      align="center"
      class="ma-0"
    >
      <v-form
        ref="form"
        lazy-validation
        v-model="valid"
        @submit.prevent="onSubmit"
      >
        <v-container>
          <v-text-field
            v-model="email"
            :rules="[$options.required, $options.email]"
            label="E-mail"
          ></v-text-field>
          <v-text-field
            v-model="password"
            :rules="[$options.required, $options.passwordLength]"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            label="Пароль"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-btn
            :disabled="!valid"
            type="submit"
          >
            Войти
          </v-btn>
        </v-container>
      </v-form>
    </v-row>
  </div>
</template>

<script>
import rulesMixin from "@/mixins/rulesMixin";
import {AUTH_REQUEST} from "@/store/actions/auth";

export default {
  name: "Login",
  data() {
    return {
      email: '',
      password: '',
      valid: true,
      showPassword: false,
    };
  },
  methods: {
    onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$store.dispatch(AUTH_REQUEST, {
        email: this.email,
        password: this.password,
      }).then(() => {
        this.$router.push({name: 'OrderList'});
      }).catch((err) => {
        console.error(err);
      });
    },
  },
  mixins: [ rulesMixin ]
}
</script>

<style scoped lang="scss">

</style>
