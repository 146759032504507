<template>
  <v-data-table
    hide-default-footer
    :headers="headers"
    :items="items"
    class="elevation-1"
    :items-per-page="100"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>{{ tableTitle }}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h6">Вы уверены что хотите удалить этот элемент?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Отмена</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">Да</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        title="Редактировать"
        class="mr-2"
        @click="editItem(item.id)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        title="Удалить"
        @click="deleteItem(item.id)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:footer>
      <slot name="footer"></slot>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'AdminDictionaryTable',
  data: () => ({
    dialog: false,
    dialogDelete: false,
    deleteItemId: -1,
  }),
  watch: {
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  methods: {
    /**
     * Редактирование элемента
     *
     * @param itemId
     */
    editItem (itemId) {
      this.$emit('edit', itemId);
    },
    /**
     * Удаление элемента
     *
     * @param itemId
     */
    deleteItem (itemId) {
      this.deleteItemId = itemId;
      this.dialogDelete = true;
    },
    /**
     * Подтверждение удаление элемента
     */
    deleteItemConfirm () {
      this.dialogDelete = false;
      this.$emit('delete', this.deleteItemId);
    },
    /**
     * Отменить удаление элемента
     */
    closeDelete () {
      this.dialogDelete = false;
      this.deleteItemId = -1;
    },
  },
  props: {
    tableTitle: { type: String, required: true },
    headers: { type: Array, required: true },
    items: { type: Array, required: true },
  }
}
</script>

<style scoped>

</style>
