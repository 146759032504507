import Vue from 'vue'
import VueRouter from 'vue-router'
import guard from "@/router/guard";
import Login from "@/views/Login.vue";
import AdminPanel from "@/views/AdminPanel.vue";
import AdminUsers from "@/views/AdminUsers.vue";
import AdminTables from "@/views/AdminTables.vue";
import OrderList from "@/views/OrderList.vue";
import UserProfile from "@/views/UserProfile.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Auth',
    component: Login,
  },
  {
    path: '/profile/settings',
    name: 'UserProfile',
    component: UserProfile
  },
  {
    path: '/admin-panel',
    name: 'AdminPanel',
    component: AdminPanel,
    meta: {
      authOf: ['admin']
    }
  },
  {
    path: '/admin-panel/users',
    name: 'AdminUsers',
    component: AdminUsers,
    meta: {
      authOf: ['admin']
    }
  },
  {
    path: '/admin-panel/dictionary',
    name: 'AdminTables',
    component: AdminTables,
    meta: {
      authOf: ['admin']
    }
  },
  {
    path: '/list',
    name: 'OrderList',
    component: OrderList,
    meta: {
      authOf: ['admin', 'content', 'rop']
    }
  }
]

/**
 * @description Роутер.
 * @constructor
 * @augments VueRouter
 */
export default function Router() {
  VueRouter.call(this, { mode: 'history', routes });
  this.beforeEach(guard);
}

Router.prototype = Object.create(VueRouter.prototype);
