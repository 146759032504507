
<template>
  <v-container>
    <v-row class="justify-space-between ma-0">
      <img src="@/assets/pm-logo.png" alt="" class="logo">
      <div class="head-actions">
        <v-btn icon @click="$router.push({ name: 'AdminPanel' })" v-if="userIsAdmin">
          <v-icon>mdi-cog</v-icon>
        </v-btn>
        <v-btn
          icon
          title="Мой профиль"
          v-on:click="$router.push({ name: 'UserProfile' })"
        >
          <v-icon>mdi-account-outline</v-icon>
        </v-btn>
      </div>
    </v-row>
    <v-row
      justify="center"
      align="center"
      class="search-row"
    >
      <v-text-field
        outlined
        hide-details
        rounded
        dense
        clearable
        v-model="searchString"
        @input="makeSearch"
      >
        <template v-slot:label>
          <v-icon dense>mdi-magnify</v-icon> Поиск
        </template>
      </v-text-field>
      <v-btn icon @click="showFilter">
        <v-icon
          :color="filterIsNotEmpty ? 'blue darken-1' : ''"
        >mdi-filter-variant</v-icon>
      </v-btn>
    </v-row>
    <v-data-table
      hide-default-footer
      disable-sort
      :headers="tableHeaders"
      :items="rows"
      :items-per-page="rows.length"
      @click:row="showOrder"
    />
    <infinity-scroll
      v-if="rows.length"
      @intersecting="nextPage"
    />
    <v-btn
      fixed
      right
      bottom
      fab
      dark
      color="blue darken-2"
      @click="openCreateOrderForm"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <dialog-order-form
      v-if="showCreateForm"
      :show="showCreateForm"
      @close="showCreateForm = false"
      :dictionaries="dictionaries"
      @make-order="createOrder"
    />
    <dialog-order-view
      v-if="showOrderDialog"
      :show="showOrderDialog"
      :order="chosenOrder"
      @delete-replacement="deleteOrderReplacement"
      @add-replacement-item="addOrderReplacement"
      @close="showOrderDialog = false"
      @edit-order="openEditOrder"
    />
    <dialog-order-form
      v-if="showEditForm"
      :show="showEditForm"
      @close="showEditForm = false"
      :dictionaries="dictionaries"
      :order="chosenOrder"
      @make-order="editOrder"
    />
    <filter-form
      v-if="showFilterForm"
      :show="showFilterForm"
      @close="showFilterForm = false"
      :filtersData="filtersData"
      :value="filters"
      @input="setFilters"
    />
  </v-container>
</template>

<script>
import DialogOrderView from '@/components/DialogOrderView.vue';
import DialogOrderForm from '@/components/DialogOrderForm.vue';
import FilterForm from "@/components/FilterForm.vue";
import debounce from "debounce";
import InfinityScroll from "@/components/InfinityScroll.vue";

export default {
  components: { InfinityScroll, FilterForm, DialogOrderView, DialogOrderForm },
  data() {
    return {
      tableHeaders: [
        { text: 'Имя', align: 'start', value: 'name', width: '150px' },
        { text: 'Телефон', value: 'phone', width: '150px' },
        { text: 'Торговая точка', value: 'sale_place', width: '140px' },
        { text: 'Стоимость', value: 'price' },
        { text: 'Комментарий', value: 'comment', width: '250px' },
        { text: 'Откуда узнал', value: 'how_find', width: '200px' },
        { text: 'Модель устройства', value: 'device_model' },
        { text: 'Вид услуги', value: 'service' },
        { text: 'Сотрудник', value: 'user' },
        { text: 'Дата', value: 'created_at' },
        { text: 'Была замена', value: 'replacements_exists', align: 'center' },
      ],
      showCreateForm: false,
      showEditForm: false,
      dictionaries: {},
      rows: [],
      chosenOrder: {},
      showOrderDialog: false,
      showFilterForm: false,
      filters: {},
      filtersData: {},
      searchString: '',
      page: 1,
      totals: 0,
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    userIsAdmin() {
      return this.$store.getters.user?.role === 'admin';
    },
    filterIsNotEmpty() {
      return this.filters.deviceModel !== undefined; // абстрактная привязка к существованию поля
    }
  },
  methods: {
    fetchData() {
      this.axios.get('/api/orders', {
        params: {
          search: this.searchString,
          page: this.page,
          ...this.filters
        }
      }).then((response) => {
        this.rows = response.data.rows;
        this.totals = response.data.totals;
      });
    },
    attachData() {
      this.axios.get('/api/orders', {
        params: {
          search: this.searchString,
          page: this.page,
          ...this.filters
        }
      }).then((response) => {
        this.rows.push(...response.data.rows);
        this.totals = response.data.totals;
      });
    },
    showOrder({ id }) {
      this.axios.get(`/api/orders/${id}`).then((response) => {
        this.chosenOrder = response.data;
        this.showOrderDialog = true;
      });
    },
    openEditOrder() {
      this.axios.get('/api/dictionaries').then((response) => {
        this.dictionaries = response.data;
        this.showEditForm = true;
      });
    },
    showFilter() {
      this.axios.get('/api/order-filters-data').then((response) => {
        this.filtersData = response.data;
        this.showFilterForm = true;
      })
    },
    setFilters(filters) {
      this.filters = filters;
      this.showFilterForm = false;
      this.searchString = '';
      this.page = 1;
      this.fetchData();
    },
    editOrder(data) {
      this.axios.put(`/api/orders/${this.chosenOrder.id}/edit`, data).then((response) => {
        this.chosenOrder = response.data;
        this.showEditForm = false;
        this.fetchData();
      });
    },
    makeSearch: debounce(function _() {
      this.filters = {};
      this.page = 1;
      this.fetchData();
    }, 500),
    /**
     * Открыть форму создания
     */
    openCreateOrderForm() {
      this.axios.get('/api/dictionaries').then((response) => {
        this.dictionaries = response.data;
        this.showCreateForm = true;
      });
    },
    /**
     * Создать запись
     * @param {object} orderData
     */
    createOrder(orderData) {
      this.axios.post('/api/orders', orderData).then(() => {
        this.resetFilters().then(() => {
          this.fetchData();
          this.showCreateForm = false;
        });
      });
    },
    nextPage() {
      if (this.rows.length < this.totals) {
        this.page = this.page + 1;
        this.attachData();
      }
    },
    resetFilters() {
      return new Promise((resolve) => {
        this.page = 1;
        this.searchString = '';
        this.filters = {};
        resolve();
      });
    },
    addOrderReplacement(data) {
      this.axios.put(`/api/order/${this.chosenOrder.id}/replacement`, data).then(() => {
        const row = this.rows.find((row) => row.id === this.chosenOrder.id);
        row.replacements_exists = 'Была';
        this.showOrder({ id: this.chosenOrder.id });
      });
    },
    deleteOrderReplacement(id) {
      this.axios.delete(`/api/order/${this.chosenOrder.id}/replacement/${id}`).then((response) => {
        this.showOrder({ id: this.chosenOrder.id });
        this.chosenOrder = response.data;
        if (!this.chosenOrder.replacements.length) {
          const row = this.rows.find((row) => row.id === this.chosenOrder.id);
          row.replacements_exists = '-';
        }
      });
    },
  }
}
</script>

<style>
 .search-row {
   gap: 20px;
   margin: 0;
   margin-bottom: 20px;
 }
 h1 {
   margin-bottom: 20px;
 }
 .head-actions {
   display: flex;
   justify-content: flex-end;
   gap: 8px;
 }
 @media screen and (max-width: 480px) {
   .v-data-table__mobile-table-row {
     background: #dcdcdc69;
     border-radius: 8px;
     margin-bottom: 10px;
   }
   .v-data-table__mobile-row {
     border-bottom: 1px solid #dcdcdc;
   }
 }
 .logo {
   width: 200px;
   filter: invert(1);
 }
</style>
