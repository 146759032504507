<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="date"
        :label="label"
        append-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        outlined
        dense
        v-on="on"
        clearable
        @click:clear="clearValue"
      />
    </template>
    <v-date-picker
      :value="date"
      first-day-of-week="1"
      next-month-aria-label="Следующий месяц"
      no-title
      prev-month-aria-label="Предыдущий месяц"
      locale="ru-ru"
      show-adjacent-months
      :header-date-format="getYearAndMonthTitle"
      :weekday-format="getWeekdayShortTitle"
      @input="emitInput"
    />
  </v-menu>
</template>

<script>

export default {
  name: "DatePicker",
  data() {
    return {
      menu: false,
      date: this.value ? this.value : null,
    };
  },
  props: {
    label: { type: String, default: () => '' },
    value: { type: String, required: true }
  },
  methods: {
    clearValue() {
      this.date = null;
      this.$emit('input', '');
    },
    emitInput(date) {
      this.menu = false;
      this.date = date;
      this.$emit('input', date);
    },
    /**
     * @description Получить короткий заголовок дня недели.
     * @param {string} isoDate Дата.
     * @returns {string}
     */
    getWeekdayShortTitle(isoDate) {
      const options = { weekday: 'short' };
      return (new Date(isoDate))
          .toLocaleDateString('ru-ru', options)
          .toUpperCase();
    },
    /**
     * @description Получить заголовок года и месяца.
     * @param {string} isoDate Дата.
     * @returns {string}
     */
    getYearAndMonthTitle(isoDate) {
      const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);
      const date = new Date(isoDate);
      const month = date.toLocaleString('ru-ru', { month: 'long' });
      const year = date.getFullYear();
      return `${capitalize(month)} ${year}`;
    },
  },
}
</script>

<style scoped>

</style>