<template>
  <div>
    <div
      class="header"
    >
      <v-btn
        @click="$router.go(-1)"
      >
        Назад
      </v-btn>
      <h1>
        Пользователи
      </h1>
      <v-btn
        class="btn-add"
        :color="`green`"
        v-on:click="showModalCreate"
      >
        Добавить пользователя
      </v-btn>
    </div>
    <div>
      <admin-user-card
        class="user-card"
        v-for="(user, key) in users"
        :key="`user-${key}`"
        v-bind:id="user.id"
        v-bind:name="user.name"
        v-bind:email="user.email"
        v-bind:active="user.active"
        v-on:change-active="changeUserActive"
        v-on:edit="getUser"
        v-on:delete="deleteUser(user.id)"
      />
    </div>
    <admin-modal-user
      v-model="showModalEditingUser"
      v-bind:user="selectedUser"
      v-on:input="editUser"
      v-on:close="closeModal"
    />
    <admin-modal-user
      v-model="showModalCreateUser"
      v-bind:user="newUser"
      v-on:input="addUser"
      v-on:close="closeModal"
    />
  </div>
</template>

<script>
import AdminUserCard from "@/components/AdminUserCard.vue";
import rulesMixin from "@/mixins/rulesMixin";
import AdminModalUser from "@/components/AdminModalUser.vue";

function User ( //
  id = '',
  name = '',
  email = '',
  password = '',
  role = '',
  active = false,
) {
  this.id = id;
  this.name = name;
  this.email = email;
  this.password = password;
  this.role = role;
  this.active = active;
}

export default {
  name: "AdminUsers",
  components: {AdminModalUser, AdminUserCard },
  data() {
    return {
      users: [],
      valid: true,
      selectedUser: new User(),
      newUser: new User(),
      showModalEditingUser: false,
      showModalCreateUser: false,
    }
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    /**
     * Получить список пользователей
     */
    fetchUsers() {
      this.axios.get('/api/admin/users')
      .then((response) => {
        this.users = response.data;
      }).catch((err) => {
        console.error(err);
      });
    },
    /**
     * Изменить активность пользователя
     *
     * @param userId
     */
    changeUserActive(userId) {
      this.axios.put(`/api/admin/users/${userId}/active`)
        .then(()=> {
          this.fetchUsers();
        }).catch((err) => {
          console.error(err);
        });
    },
    /**
     * Удалить пользователя
     *
     * @param userId
     */
    deleteUser(userId) {
      if (!confirm("Вы действительно хотите удалить пользователя?")) {
        return;
      }
      this.axios.delete(`/api/admin/users/${userId}/delete`)
        .then(()=> {
          this.fetchUsers();
        }).catch((err) => {
          console.error(err);
        });
      this.fetchUsers();
    },
    /**
     * Вызвать попап по изменению данных пользователя
     *
     * @param userId
     */
    getUser(userId) {
      this.axios.get(`/api/admin/users/${userId}`)
        .then((response) => {
          const user = response.data.user;
          this.selectedUser = new User(
            user.id,
            user.name,
            user.email,
            '',
            user.role,
            user.active,
          );
          this.showModalEditingUser = true;
        }).catch((err) => {
          console.error(err);
        });
    },
    /**
     * Редактирвоание пользователя
     *
     * @param user
     */
    editUser(user) {
      this.axios.put(`/api/admin/users/${user.id}/edit`, user)
        .then(() => {
          this.showModalEditingUser = false;
          this.fetchUsers();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * Добавление нового пользователя
     *
     * @param user
     */
    addUser(user) {
      console.log(user);
      this.axios.post(`/api/admin/users/create`, user)
        .then(() => {
          this.showModalCreateUser = false;
          this.fetchUsers();
        })
        .catch((err) => {
          console.error(err)
        })
    },
    /**
     * Закрытие попапа
     */
    closeModal() {
      this.showModalEditingUser = false;
      this.showModalCreateUser = false;
    },
    /**
     * Добавление нового пользователя
     */
    showModalCreate() {
      this.newUser = new User();
      this.showModalCreateUser = true;
    }
  },
  mixins: [ rulesMixin ],
}
</script>

<style scoped>
h1 {
  font-weight: 600;
  font-size: 32px;
  margin-left: 10%;
}
.user-card {
  margin: 10px;
}
.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px;
}
.btn-add {
  margin-left: auto;
}
</style>
