<template>
  <div>
    <div
      class="header"
    >
      <v-btn
        @click="$router.go(-1)"
      >
        Назад
      </v-btn>
      <h1>
        Словари
      </h1>
    </div>
    <admin-dictionary-table
      style="margin: 10px"
      v-bind:headers="headers"
      v-bind:items="salePlaces"
      v-bind:table-title="`Торговые точки`"
      v-on:delete="deleteSalePlace"
      v-on:edit="editSalePlace"
    >
      <template v-slot:footer>
        <div class="table-footer">
          <v-text-field
            v-model="newSalePlace"
            label="Новое место"
          ></v-text-field>
          <v-btn
            class="btn-add"
            :color="`green`"
            v-on:click="addNewSalePlace"
          >
            Добавить
          </v-btn>
        </div>
        <v-dialog
          v-model="modalDialogSalePlace"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h6"> Редактирование торговой точки </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="selectedSalePlace.name"
                      label="Наименование"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeModal"
              >
                Отмена
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="saveEditSalePlace"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </admin-dictionary-table>
    <admin-dictionary-table
      style="margin: 10px"
      v-bind:headers="headers"
      v-bind:items="backMaterials"
      v-bind:table-title="`Материалы корпуса`"
      v-on:delete="deleteBackMaterial"
      v-on:edit="editBackMaterial"
    >
      <template v-slot:footer>
        <div class="table-footer">
          <v-text-field
            v-model="newBackMaterial"
            label="Новый материал корпуса"
          ></v-text-field>
          <v-btn
            class="btn-add"
            :color="`green`"
            v-on:click="addBackMaterial"
          >
            Добавить
          </v-btn>
        </div>
        <v-dialog
          v-model="modalDialogBackMaterial"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h6"> Редактирование материала корпуса </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="selectedBackMaterial.name"
                      label="Наименование"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeModal"
              >
                Отмена
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="saveEditBackMaterial"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </admin-dictionary-table>
    <admin-dictionary-table
      style="margin: 10px"
      v-bind:headers="headers"
      v-bind:items="screenMaterials"
      v-bind:table-title="`Материалы дисплея`"
      v-on:delete="deleteScreenMaterial"
      v-on:edit="editScreenMaterial"
    >
      <template v-slot:footer>
        <div class="table-footer">
          <v-text-field
            v-model="newScreenMaterial"
            label="Новый материал дисплея"
          ></v-text-field>
          <v-btn
            class="btn-add"
            :color="`green`"
            v-on:click="addScreenMaterial"
          >
            Добавить
          </v-btn>
        </div>
        <v-dialog
          v-model="modalDialogScreenMaterial"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h6"> Редактирование материала дисплея </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="selectedScreenMaterial.name"
                      label="Наименование"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeModal"
              >
                Отмена
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="saveEditScreenMaterial"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </admin-dictionary-table>
    <admin-dictionary-table
      style="margin: 10px"
      v-bind:headers="serviceHeaders"
      v-bind:items="services"
      v-bind:table-title="`Услуги`"
      v-on:delete="deleteService"
      v-on:edit="editService"
    >
      <template v-slot:footer>
        <div class="table-footer">
          <v-row>
            <v-col>
              <v-text-field
                  v-model="newService"
                  label="Новая услуга"
              ></v-text-field>
              <v-text-field
                  v-model="newServiceWarranty"
                  label="Гарантийный текст"
              ></v-text-field>
            </v-col>
            <v-col class="col-2">
              <v-btn
                  class="btn-add"
                  :color="`green`"
                  v-on:click="addService"
              >
                Добавить
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-dialog
          v-model="modalDialogService"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h6"> Редактирование услуги </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="selectedService.name"
                      label="Наименование"
                    ></v-text-field>
                    <v-text-field
                        v-model="selectedService.warranty"
                        label="Наименование"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeModal"
              >
                Отмена
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="saveEditService"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </admin-dictionary-table>
    <admin-dictionary-table
      style="margin: 10px"
      v-bind:headers="headers"
      v-bind:items="replacementItems"
      v-bind:table-title="`Заменяемые части`"
      v-on:delete="deleteReplacementItem"
      v-on:edit="editReplacementItem"
    >
      <template v-slot:footer>
        <div class="table-footer">
          <v-text-field
            v-model="newReplacementItem"
            label="Новая заменяемая часть"
          ></v-text-field>
          <v-btn
            class="btn-add"
            :color="`green`"
            v-on:click="addReplacementItem"
          >
            Добавить
          </v-btn>
        </div>
        <v-dialog
          v-model="modalDialogReplacementItem"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h6"> Редактирование заменяемой части </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="selectedReplacementItem.name"
                      label="Наименование"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeModal"
              >
                Отмена
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="saveEditReplacementItem"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </admin-dictionary-table>
    <admin-dictionary-table
      style="margin: 10px"
      v-bind:headers="headers"
      v-bind:items="howFinds"
      v-bind:table-title="`Как нашли`"
      v-on:delete="deleteHowFind"
      v-on:edit="editHowFind"
    >
      <template v-slot:footer>
        <div class="table-footer">
          <v-text-field
            v-model="newHowFind"
            label="Новый метод"
          ></v-text-field>
          <v-btn
            class="btn-add"
            :color="`green`"
            v-on:click="addHowFind"
          >
            Добавить
          </v-btn>
        </div>
        <v-dialog
          v-model="modalDialogHowFind"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h6"> Редактирование метода </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="selectedHowFind.name"
                      label="Наименование"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeModal"
              >
                Отмена
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="saveEditHowFind"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </admin-dictionary-table>
  </div>
</template>

<script>
import AdminDictionaryTable from "@/components/AdminDictionaryTable.vue";
import rulesMixin from "@/mixins/rulesMixin";

function Item (
  id = '',
  name = '',
) {
  this.id = id;
  this.name = name;
}

export default {
  components: { AdminDictionaryTable },
  created() {
    this.fetchSalePlaces();
    this.fetchBackMaterials();
    this.fetchScreenMaterials();
    this.fetchServices();
    this.fetchReplacementItems();
    this.fetchHowFind();
  },
  data () {
    return {
      backMaterials: [],
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Наименование', value: 'name', sortable: false },
        { text: 'Действия', value: 'actions', sortable: false },
      ],
      serviceHeaders: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Наименование', value: 'name', sortable: false },
        { text: 'Гарантия', value: 'warranty', sortable: false },
        { text: 'Действия', value: 'actions', sortable: false },
      ],
      modalDialogSalePlace: false,
      modalDialogBackMaterial: false,
      modalDialogScreenMaterial: false,
      modalDialogService: false,
      modalDialogReplacementItem: false,
      modalDialogHowFind: false,
      newBackMaterial: '',
      newSalePlace: '',
      newScreenMaterial: '',
      newService: '',
      newServiceWarranty: '',
      newHowFind: '',
      newReplacementItem: '',
      selectedBackMaterial: new Item(),
      selectedSalePlace: new Item(),
      selectedScreenMaterial: new Item(),
      selectedService: new Item(),
      selectedHowFind: new Item(),
      selectedReplacementItem: new Item(),
      salePlaces: [],
      screenMaterials: [],
      services: [],
      howFinds: [],
      replacementItems: [],
    }
  },
  methods: {
    /**
     * Добавление материала корпуса
     */
    addBackMaterial() {
      this.axios.post('/api/admin/back-materials/create', {name: this.newBackMaterial})
        .then((response) => {
          this.fetchBackMaterials();
          this.newBackMaterial = '';
        }).catch((err) => {
        console.error(err);
      });
    },
    /**
     * Добавление новой торговой точки
     */
    addNewSalePlace() {
      this.axios.post('/api/admin/sale-places/create', {name: this.newSalePlace})
        .then((response) => {
          this.fetchSalePlaces();
          this.newSalePlace = '';
        }).catch((err) => {
        console.error(err);
      });
    },
    /**
     * Добавление материала экрана
     */
    addScreenMaterial() {
      this.axios.post('/api/admin/screen-materials/create', {name: this.newScreenMaterial})
        .then((response) => {
          this.fetchScreenMaterials();
          this.newScreenMaterial = '';
        }).catch((err) => {
        console.error(err);
      });
    },
    /**
     * Добавление услуги
     */
    addService() {
      this.axios.post('/api/admin/services/create', {
        name: this.newService,
        warranty: this.newServiceWarranty,
      })
        .then((response) => {
          this.fetchServices();
          this.newService = '';
          this.newServiceWarranty = '';
        }).catch((err) => {
        console.error(err);
      });
    },
    /**
     * Добавление места поиска
     */
    addHowFind() {
      this.axios.post('/api/admin/how-find/create', {name: this.newHowFind})
      .then((response) => {
        this.fetchHowFind();
        this.newHowFind = '';
      }).catch((err) => {
        console.error(err);
      });
    },
    /**
     * Добавление заменяемой части
     */
    addReplacementItem() {
      this.axios.post('/api/admin/replacement-items/create', {name: this.newReplacementItem})
        .then((response) => {
          this.fetchReplacementItems();
          this.newReplacementItem = '';
        }).catch((err) => {
        console.error(err);
      });
    },
    /**
     * Изменение материала экрана
     *
     * @param id
     */
    editBackMaterial(id) {
      this.axios.get(`/api/admin/back-materials/${id}`)
        .then((response) => {
          this.selectedBackMaterial = new Item(response.data.id, response.data.name);
          this.modalDialogBackMaterial = true;
        }).catch((err) => {
          console.error(err);
        });
    },
    /**
     * Изменение точки продаж
     *
     * @param id
     */
    editSalePlace(id) {
      this.axios.get(`/api/admin/sale-places/${id}`)
        .then((response) => {
          this.selectedSalePlace = new Item(response.data.id, response.data.name);
          this.modalDialogSalePlace = true;
        }).catch((err) => {
          console.error(err);
        });
    },
    /**
     * Изменение материала экрана
     *
     * @param id
     */
    editScreenMaterial(id) {
      this.axios.get(`/api/admin/screen-materials/${id}`)
        .then((response) => {
          this.selectedScreenMaterial = new Item(response.data.id, response.data.name);
          this.modalDialogScreenMaterial = true;
        }).catch((err) => {
        console.error(err);
      });
    },
    /**
     * Изменение услуги
     *
     * @param id
     */
    editService(id) {
      this.axios.get(`/api/admin/services/${id}`)
        .then((response) => {
          this.selectedService = {
            id: response.data.id,
            name: response.data.name,
            warranty: response.data.warranty
          };
          this.modalDialogService = true;
        }).catch((err) => {
        console.error(err);
      });
    },
    /**
     * Изменение места поиска
     *
     * @param id
     */
    editHowFind(id) {
      this.axios.get(`/api/admin/how-find/${id}`)
      .then((response) => {
        this.selectedHowFind = new Item(response.data.id, response.data.name);
        this.modalDialogHowFind = true;
      }).catch((err) => {
        console.error(err);
      });
    },
    /**
     * Изменение заменяемой части
     *
     * @param id
     */
    editReplacementItem(id) {
      this.axios.get(`/api/admin/replacement-items/${id}`)
        .then((response) => {
          this.selectedReplacementItem = new Item(response.data.id, response.data.name);
          this.modalDialogReplacementItem = true;
        }).catch((err) => {
        console.error(err);
      });
    },
    /**
     * Закрыть модальное окно
     */
    closeModal() {
      this.modalDialogSalePlace = false;
      this.modalDialogBackMaterial = false;
      this.modalDialogScreenMaterial = false;
      this.modalDialogService = false;
      this.modalDialogReplacementItem = false;
      this.modalDialogHowFind = false;
    },
    /**
     * Удаление материала экрана
     *
     * @param id
     */
    deleteBackMaterial(id) {
      this.axios.delete(`/api/admin/back-materials/${id}/delete`)
        .then((response) => {
          this.fetchBackMaterials();
        }).catch((err) => {
          console.error(err);
        });
    },
    /**
     * Удаление точки продаж
     *
     * @param id
     */
    deleteSalePlace(id) {
      this.axios.delete(`/api/admin/sale-places/${id}/delete`)
        .then((response) => {
          this.fetchSalePlaces();
        }).catch((err) => {
          console.error(err);
        });
    },
    /**
     * Удаление материала экрана
     *
     * @param id
     */
    deleteScreenMaterial(id) {
      this.axios.delete(`/api/admin/screen-materials/${id}/delete`)
        .then((response) => {
          this.fetchScreenMaterials();
        }).catch((err) => {
        console.error(err);
      });
    },
    /**
     * Удаление услуги
     *
     * @param id
     */
    deleteService(id) {
      this.axios.delete(`/api/admin/services/${id}/delete`)
        .then((response) => {
          this.fetchServices();
        }).catch((err) => {
        console.error(err);
      });
    },
    /**
     * Удаление места поиска
     *
     * @param id
     */
    deleteHowFind(id) {
      this.axios.delete(`/api/admin/how-find/${id}/delete`)
      .then((response) => {
        this.fetchHowFind();
      }).catch((err) => {
        console.error(err);
      });
    },
    /**
     * Удаление заменяемой части
     *
     * @param id
     */
    deleteReplacementItem(id) {
      this.axios.delete(`/api/admin/replacement-items/${id}/delete`)
        .then((response) => {
          this.fetchReplacementItems();
        }).catch((err) => {
          console.error(err);
      });
    },
    /**
     * Получить список материалов корпуса
     */
    fetchBackMaterials() {
      this.axios.get('/api/admin/back-materials')
        .then((response) => {
          this.backMaterials = response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * Получить список точек продаж
     */
    fetchSalePlaces() {
      this.axios.get('/api/admin/sale-places')
        .then((response) => {
          this.salePlaces = response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * Получить список материалов экрана
     */
    fetchScreenMaterials() {
      this.axios.get('/api/admin/screen-materials')
        .then((response) => {
          this.screenMaterials = response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * Получить список услуг
     */
    fetchServices() {
      this.axios.get('/api/admin/services')
        .then((response) => {
          this.services = response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * Получить список мест поиска
     */
    fetchHowFind() {
      this.axios.get('/api/admin/how-find')
      .then((response) => {
        this.howFinds = response.data;
      })
      .catch((err) => {
        console.error(err);
      });
    },
    /**
     * Получить список заменяемых частей
     */
    fetchReplacementItems() {
      this.axios.get('/api/admin/replacement-items')
        .then((response) => {
          this.replacementItems = response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    /**
     * Сохранить изменения материала корпуса
     */
    saveEditBackMaterial() {
      this.axios.put(`/api/admin/back-materials/${this.selectedBackMaterial.id}/edit`, this.selectedBackMaterial)
      .then((response) => {
        this.closeModal();
        this.fetchBackMaterials();
      })
      .catch((err) => {
        console.error(err);
      });
    },
    /**
     * Сохранить изменения точки продаж
     */
    saveEditSalePlace() {
      this.axios.put(`/api/admin/sale-places/${this.selectedSalePlace.id}/edit`, this.selectedSalePlace)
        .then((response) => {
          this.closeModal();
          this.fetchSalePlaces();
        }).catch((err) => {
          console.error(err);
        });
    },
    /**
     * Сохранить изменения материала экрана
     */
    saveEditScreenMaterial() {
      this.axios.put(`/api/admin/screen-materials/${this.selectedScreenMaterial.id}/edit`, this.selectedScreenMaterial)
        .then((response) => {
          this.closeModal();
          this.fetchScreenMaterials();
        }).catch((err) => {
        console.error(err);
      });
    },
    /**
     * Сохранить изменения по услуге
     */
    saveEditService() {
      this.axios.put(`/api/admin/services/${this.selectedService.id}/edit`, this.selectedService)
        .then((response) => {
          this.closeModal();
          this.fetchServices();
        }).catch((err) => {
        console.error(err);
      });
    },
    /**
     * Сохранить место поиска
     */
    saveEditHowFind() {
      this.axios.put(`/api/admin/how-find/${this.selectedHowFind.id}/edit`, this.selectedHowFind)
      .then((response) => {
        this.closeModal();
        this.fetchHowFind();
      }).catch((err) => {
        console.error(err);
      });
    },
    /**
     * Сохранить изменения по заменяемой части
     */
    saveEditReplacementItem() {
      this.axios.put(`/api/admin/replacement-items/${this.selectedReplacementItem.id}/edit`, this.selectedReplacementItem)
      .then((response) => {
        this.closeModal();
        this.fetchReplacementItems();
      }).catch((err) => {
        console.error(err);
      });
    },
  },
  mixins: [ rulesMixin ],
  name: "AdminTables",
}
</script>

<style scoped>
.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px;
}
h1 {
  font-weight: 600;
  font-size: 32px;
  margin-left: 10%;
}
.table-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 5px;
}
.btn-add {
  margin: 0;
}
</style>
