import store from '@/store';

async function userLoad(next) {
    if (store.getters.isUserLoaded) { return true; }
    try {
        await store.dispatch('USER_REQUEST');
    } catch (error) {
        next('/login');
        return false;
    }
    return true;
}

async function guard(to, from, next) {
    if (!to.name) {
        next({ name: 'Auth' });
        return;
    }
    if (to.meta.authOf === undefined) {
        next();
        return;
    }
    await userLoad(next);
    const isAllowed = to.meta.authOf.includes(store.getters.user?.role);
    if (isAllowed) {
        next();
    } else {
        next('/');
    }
}

export default guard;
