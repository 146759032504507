<template>
  <v-dialog
    :value="value"
    @input="$emit('close', $event)"
    width="550"
  >
    <v-form
      ref="form"
      class="dialog-form"
      lazy-validation
      v-model="valid"
      @submit.prevent="onSubmit"
      style="position: relative"
    >
      <v-btn
        v-on:click="onClose"
        icon
        color="red"
        absolute
        right
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-container>
        <v-text-field
          v-model="user.name"
          :rules="[$options.required]"
          label="Имя"
        ></v-text-field>
        <v-text-field
          v-model="user.email"
          :rules="[$options.required, $options.email]"
          label="E-mail"
        ></v-text-field>
        <v-text-field
          v-model="user.password"
          :rules="[$options.passwordLength]"
          label="Пароль"
        ></v-text-field>
        <v-select
          v-model="user.role"
          :rules="[$options.required]"
          :items="roles"
        >
        </v-select>
        <v-checkbox
          v-model="user.active"
          label="Активность"
        ></v-checkbox>
        <v-btn
          :disabled="!valid"
          type="submit"
        >
          {{ btnSubmitText }}
        </v-btn>
      </v-container>
    </v-form>
  </v-dialog>
</template>

<script>
import rulesMixin from "@/mixins/rulesMixin";

export default {
  name: "AdminModalUser",
  computed: {
    /**
     * Текст кнопки сохранения/создания пользователя
     *
     * @returns {string}
     */
    btnSubmitText() {
      return this.user.id ? 'Сохранить изменения' : 'Создать пользователя';
    },
  },
  data() {
    return {
      valid: true,
      roles: [
        {
          text: "Контент-менеджер",
          value: "content",
        },
        {
          text: "Админ",
          value: "admin",
        },
        {
          text: 'РОП',
          value: 'rop'
        }
      ]
    }
  },
  mixins: [ rulesMixin ],
  methods: {
    /**
     * Событие закрытия модалки
     */
    onClose() {
      this.$emit('close');
    },
    /**
     * Событие отправки данных
     */
    onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit('input', this.user);
    }
  },
  props: {
    user: { type: Object, required: true },
    value: { type: Boolean, required: true }
  }
}
</script>

<style scoped>
.dialog-form {
  background: white;
}
</style>
