<template>
  <v-dialog
    :value="show"
    @input="$emit('close')"
    width="550"
  >
    <v-form
      class="dialog-form"
      @submit.prevent="makeOrder"
      ref="form"
      lazy-validation
      v-model="valid"
    >
      <v-row
        class="head-block justify-space-between"
      >
        <h2>Создание клиента</h2>
        <v-btn
          icon
          color="red"
          @click="$emit('close')"
        >
          <v-icon>
            mdi-close-thick
          </v-icon>
        </v-btn>
      </v-row>
      <v-text-field
        outlined dense label="Имя" v-model="name" :rules="[$options.required]"
        :disabled="cantEdit"
      />
      <v-text-field
        v-if="cantEdit"
        v-model="phone"
        readonly
        outlined
        dense
        label="Телефон"
      />
      <v-text-field
        v-else
        outlined
        dense
        label="Телефон"
        v-mask="'+7 ### ### ## ##'"
        v-model="phone"
        placeholder="+7 900 000 00 00"
        append-icon="mdi-plus-circle-outline"
        @click:append="addAdditionalPhone"
        :rules="[$options.required, $options.fullNumber]"
      />
      <template
        v-for="(adPhone, key) in additionalPhones"
      >
        <v-text-field
          v-if="order.id && !userIsAdmin"
          :key="key"
          v-model="additionalPhones[key]"
          readonly
          outlined
          dense
          color="purple"
        />
        <v-text-field
          v-else
          :key="key"
          dense
          v-mask="'+7 ### ### ## ##'"
          v-model="additionalPhones[key]"
          outlined
          placeholder="+7 900 000 00 00"
          append-icon="mdi-close-circle-outline"
          color="purple"
          @click:append="deleteAdditionalPhone(key)"
          :rules="[$options.required, $options.fullNumber]"
        />
      </template>
      <v-text-field
        outlined
        dense
        label="Стоимость"
        type="number"
        :rules="[$options.required]"
        v-model="price"
        :disabled="cantEdit"
      />
      <v-textarea
        label="Комментарий"
        dense
        auto-grow
        outlined
        rows="1"
        :disabled="!!was_imported"
        v-model="comment"
      />
      <v-text-field
        v-model="deviceModel" outlined dense label="Модель устройства" :rules="[$options.required]"
        :disabled="cantEdit"
      />
      <v-row class="ma-0">
        <v-select
          outlined
          dense
          label="Вид услуги"
          :rules="[$options.required]"
          item-value="id"
          item-text="name"
          :items="dictionaries.services"
          v-model="serviceId"
          :disabled="cantEdit"
        />
        <v-btn
          v-if="selectedService?.warranty"
          icon @click="showWarranty = true"
        >
          <v-icon>mdi-help-circle-outline</v-icon>
        </v-btn>
      </v-row>
      <v-select
        outlined
        dense
        label="Торговая точка"
        :rules="[$options.required]"
        item-value="id"
        item-text="name"
        :items="dictionaries.salePlaces"
        v-model="salePlaceId"
        :disabled="cantEdit"
      />
      <v-select
          outlined
          dense
          label="Как нашли"
          :rules="[$options.required]"
          item-value="id"
          item-text="name"
          :items="dictionaries.howFinds"
          v-model="howFindId"
          clearable
          :disabled="cantEdit"
      />
      <v-select
        outlined
        dense
        label="Материал корпуса"
        item-value="id"
        item-text="name"
        :items="dictionaries.backMaterial"
        v-model="backMaterialId"
        clearable
        :disabled="cantEdit"
      />
      <v-select
        outlined
        dense
        label="Материал дисплея"
        item-value="id"
        item-text="name"
        :items="dictionaries.screenMaterial"
        v-model="screenMaterialId"
        clearable
        :disabled="cantEdit"
      />
      <v-btn
        dark
        color="indigo"
        block
        type="submit"
      >
        Сохранить
      </v-btn>
    </v-form>
    <v-dialog
        v-model="showWarranty"
        width="500"
    >
      <v-container style="background: white; position: relative">
        <v-row class="ma-0 justify-space-between">
          <h2>Гарантия услуги: {{selectedService?.name}}</h2>
          <v-btn
              icon
              @click="showWarranty = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        {{ selectedService?.warranty }}
      </v-container>
    </v-dialog>
  </v-dialog>
</template>

<script>
import rulesMixin from "@/mixins/rulesMixin";

export default {
  mixins: [ rulesMixin ],
  computed: {
    userIsAdmin() {
      return this.$store.getters.user?.role === 'admin';
    },
    cantEdit() {
      return !this.userIsAdmin && this.order?.id;
    },
    selectedService() {
      return this.dictionaries.services.find((service) => service.id === this.serviceId);
    }
  },
  data() {
    let phone = '';
    let additionalPhones = [];
    let phones = this.order?.phones;
    if (phones?.length) {
      phones = [...phones];
      phone = phones.shift();
      additionalPhones = phones;
    }
    let serviceId = this.order?.service_id ?? '';
    const hasService = this.dictionaries.services.find((service) => service.id === this.order?.service_id);
    if (hasService === undefined) {
      serviceId = '';
    }
    return {
      name: this.order?.name ?? '',
      phone,
      price: this.order?.price ?? '',
      comment: this.order?.comment ?? '',
      additionalPhones,
      howFindId: this.order?.how_find_id ?? 0,
      deviceModel: this.order?.device_model ?? '',
      was_imported: this.order?.was_imported ?? 0,
      // selectors
      serviceId,
      salePlaceId: this.order?.sale_place_id ?? '',
      backMaterialId: this.order?.back_material_id ?? '',
      screenMaterialId: this.order?.screen_material_id ?? '',
      // form
      valid: true,
      showWarranty: false,
    };
  },
  name: 'DialogOrderForm',
  props: {
    show: { type: Boolean, default: () => false},
    order: { type: Object, default: () => ({}) },
    dictionaries: { type: Object, required: true }
  },
  methods: {
    /**
     * Добавить дополнительный телефон
     */
    addAdditionalPhone() {
      this.additionalPhones.push('');
    },
    /**
     * Удалить дополнительный телефон
     * @param {number} keyToDelete
     */
    deleteAdditionalPhone(keyToDelete) {
      this.additionalPhones = this.additionalPhones.filter((item, key) => key !== keyToDelete);
    },
    /**
     * Создать запись
     */
    makeOrder() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit('make-order', {
        name: this.name,
        phone: this.phone,
        price: this.price,
        comment: this.comment,
        additionalPhones: this.additionalPhones,
        howFindId: this.howFindId,
        deviceModel: this.deviceModel,
        serviceId: this.serviceId,
        salePlaceId: this.salePlaceId,
        backMaterialId: this.backMaterialId,
        screenMaterialId: this.screenMaterialId,
      });
    },
  },
};
</script>

<style scoped>
.dialog-form {
    background: white;
    padding: 25px;
  }
.head-block {
  margin: 0 0 15px;
}
</style>
