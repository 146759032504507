<template>
  <v-card
    style="width: 99%"
  >
    <v-card-title>
      {{ name }}
    </v-card-title>
    <v-card-text>
      Электронная почта: {{ email }}
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-on:click="changeActive"
      >
        {{ btnText }}
      </v-btn>
      <v-btn
        v-on:click="edit"
        :color="`blue`"
      >
        Редактировать
      </v-btn>
      <v-btn
        v-on:click="deleteUser"
        :color="`red`"
      >
        Удалить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "AdminUserCard",
  computed: {
    /**
     * Текст кнопки активности в зависимости от активности пользователя
     *
     * @returns {string}
     */
    btnText() {
      return this.active ? 'Деактивировать' : 'Активировать';
    }
  },
  props: {
    id: { type: Number, required: true },
    name: { type: String, required: true },
    email: { type: String, required: true},
    active: { type: Boolean, required: true},
  },
  methods: {
    /**
     * Изменение активности пользователя
     */
    changeActive() {
      this.$emit('change-active', this.id);
    },
    /**
     * Удаление пользователя
     */
    deleteUser() {
      this.$emit('delete', this.id);
    },
    /**
     * Открыть окно изменения пользователя
     */
    edit() {
      this.$emit('edit', this.id);
    }
  }
}
</script>

<style scoped>

</style>
