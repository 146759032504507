import Vue from 'vue'
import App from './App.vue'
import Router from './router'
import store from './store'
import VueAxios from "vue-axios";
import {createAxiosWithErrorHandlers} from "@/plugins/axios";
import {AUTH_LOGOUT} from "@/store/actions/auth";
import vuetify from './plugins/vuetify'
import VueMask from 'v-mask'

Vue.use(VueMask);

const router = new Router();
const resolveToken = () => store.getters.token;

Vue.config.productionTip = false
const storageAxios = createAxiosWithErrorHandlers(
    process.env.VUE_APP_ROOT_API,
    () => { store.dispatch(AUTH_LOGOUT).then(() => { router.push({ name: 'Auth' }); }); },
    () => {alert('Произошла ошибка сервера')},
    resolveToken,
);

Vue.use(VueAxios, storageAxios);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
