<template>
  <v-dialog
    :value="show"
    @input="$emit('close')"
    width="650"
  >
    <v-container
      class="view-container"
      style="background: white"
    >
      <v-row
        class="ma-0"
      >
        <v-col class="pl-0">
          <h2>{{order.name}}</h2>
        </v-col>
        <v-col class="pr-0 actions">
          <v-btn
            icon
            title="Редактировать"
            color="blue darken-3"
            @click="$emit('edit-order')"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            icon
            color="red accent-1"
            @click="$emit('close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
        <v-col class="col-12 pl-0 font-weight-bold">Информация</v-col>
      </v-row>
      <v-row
        class="ma-0 data-container"
        style="background: white"
      >
        <v-col class="col-4 data-title">Телефон</v-col><v-col class="col-8">{{ firstPhone }}</v-col>
        <v-col class="col-4 data-title">Торговая точка</v-col><v-col class="col-8">{{order.sale_place.name}}</v-col>
        <v-col class="col-4 data-title">Стоимость</v-col><v-col class="col-8">{{order.price}}</v-col>
        <v-col class="col-4 data-title">Комментарии</v-col><v-col class="col-8">{{order.comment}}</v-col>
        <v-col class="col-4 data-title">Откуда узнал</v-col><v-col class="col-8">{{order.how_find}}</v-col>
        <v-col class="col-4 data-title">Модель устройства</v-col><v-col class="col-8">{{order.device_model}}</v-col>
        <v-col class="col-4 data-title">Вид услуги</v-col>
        <v-col class="col-8">{{order.service.name}}
          <v-btn
              v-if="order.service.warranty"
              icon @click="showWarranty = true">
            <v-icon>mdi-help-circle-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col class="col-4 data-title">Материал корпуса</v-col><v-col class="col-8">{{order.back_material?.name ?? '-'}}</v-col>
        <v-col class="col-4 data-title">Материал дисплея</v-col><v-col class="col-8">{{order.screen_material?.name ?? '-'}}</v-col>
        <v-col class="col-4 data-title">Создан</v-col><v-col class="col-8">{{order.created_at}}</v-col>
        <v-col class="col-4 data-title">Установщик</v-col><v-col class="col-8">{{order.user}}</v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col class="col-12 pl-0 font-weight-bold">Гарантии</v-col>
      </v-row>
      <v-row
        class="ma-0 pa-2 data-container"
        style="background: white"
      >
        <v-row
          class="ma-0 data-container"
          style="position: relative"
          v-for="(replacement, index) in order.replacements"
          :key="`replacement-${index}`"
        >
          <v-col class="col-5 data-title">Что заменил</v-col><v-col class="col-7">{{ replacement.name }}</v-col>
          <v-col class="col-5 data-title">Платно</v-col><v-col class="col-7" v-if="replacement.isPaid">Да</v-col><v-col class="col-7" v-else>-</v-col>
          <v-col class="col-5 data-title">Комментарий</v-col><v-col class="col-7">{{ replacement.comment ? replacement.comment : '-' }}</v-col>
          <v-col class="col-5 data-title">Сотрудник</v-col><v-col class="col-7">{{ replacement.employeeName }}</v-col>
          <v-col class="col-5 data-title">Дата</v-col><v-col class="col-7">{{ replacement.createdAt }}</v-col>
          <v-btn
            v-if="userIsAdmin"
            @click="$emit('delete-replacement', replacement.id)"
            absolute
            fab
            color="red lighten-1"
            dark
            right
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-form
          ref="form"
          class="col-12"
          lazy-validation
          v-model="valid"
          @submit.prevent="addReplacementItem"
        >
          <v-select
            item-value="id"
            item-text="name"
            dense
            outlined
            label="Что заменил"
            v-model="replacement.replacementItemId"
            :rules="[$options.required]"
            :items="order.replacement_items"
          />
          <v-checkbox
            class="mt-0"
            label="Платно"
            v-model="replacement.isPaid"
          >
          </v-checkbox>
          <v-textarea
              label="Комментарий"
              dense
              auto-grow
              outlined
              rows="1"
              v-model="replacement.comment"
          />
          <v-btn
              type="submit"
              color="green darken-2"
              dark
          >
            Добавить
          </v-btn>
        </v-form>
      </v-row>
      <v-dialog
        v-model="showWarranty"
        width="500"
      >
        <v-container style="background: white; position: relative">
          <v-row class="ma-0 justify-space-between">
            <h2>Гарантия услуги: {{order.service.name}}</h2>
            <v-btn
                icon
                @click="showWarranty = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
          {{ order.service.warranty }}
        </v-container>
      </v-dialog>
    </v-container>
  </v-dialog>
</template>

<script>
import rulesMixin from "@/mixins/rulesMixin";

export default {
  name: 'DialogOrderView',
  data() {
    return {
      valid: true,
      replacement: {
        orderId: this.order.id,
        replacementItemId: '',
        comment: '',
        isPaid: false
      },
      showWarranty: false,
    }
  },
  methods: {
    addReplacementItem() {
      if (!this.$refs.form.validate()) {
        return;
      }
      return this.$emit('add-replacement-item', this.replacement)
    }
  },
  computed: {
    firstPhone() {
      return this.order.phones[0];
    },
    userIsAdmin() {
      return this.$store.getters.user?.role === 'admin';
    },
  },
  props: {
    order: { type: Object, required: true },
    show: { type: Boolean, required: true },
  },
  mixins: [ rulesMixin ],
};
</script>

<style scoped>
 .data-container {
   border: 1px solid rgb(229, 229, 229);
   border-radius: 4px;
   padding: 8px;
 }
 .data-title {
   color: rgba(0, 0, 0, 0.38);
 }
 .actions {
   display: flex;
   gap: 8px;
   justify-content: flex-end;
 }
 .view-container {
   padding: 30px;
 }
 @media screen and (max-width: 600px) {
   .view-container {
     padding: 5px;
   }
   .data-container {
     padding: 0;
   }
 }
</style>
