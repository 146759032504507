<template>
  <div id="scroll"></div>
</template>

<script>

export default {
  name: "InfinityScroll",
  data() {
    return {
      observer: null,
    };
  },
  mounted() {
    const observer = new IntersectionObserver(this.scrollIntersection);
    observer.observe(document.querySelector('#scroll'));
  },
  methods: {
    scrollIntersection(entries) {
      if (entries[0].isIntersecting) {
        this.$emit('intersecting');
      }
    }
  }
}
</script>

<style scoped>

</style>