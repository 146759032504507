/**
 * Примесь правил полей ввода
 */
export default {
    required: (value) => {
        if (value !== undefined && value.constructor === String) {
            value = value.trim();
        }
        if (!!value) {
            return true;
        }
        return 'Обязательное поле!';
    },
    email: (value) => {
        if (/.+@.+\..+/.test(value)) {
            return true;
        }
        return 'Некорректный E-mail.';
    },
    passwordLength: (value) => {
        if (value?.length > 8 || value.length === 0) {
            return true;
        }
        return 'Пароль должен превышать 8 символов.';
    },
    passwordConfirm: (password, passwordConfirm) => {
        if (password === passwordConfirm) {
            return true;
        }
        return  'Пароли не совпадают!';
    },
    fullNumber(phone) {
        if (phone.length === 16) {
            return true;
        }
        return 'Необходимо ввести номер полностью'
    }
}
